import React from 'react';
import Layout from '../components/Layout';
import Contact from '../components/Contact/Contact';

const ContactPage = () => {
  return (
    <Layout
      title="Dr. Gio Valiante | Contact us"
      description="Reach out to Dr. Gio Valiante through this form to book him for speaking engagements, coaching, and more."
    >
      <Contact />
    </Layout>
  );
};

export default ContactPage;
