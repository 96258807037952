import React, { useState } from 'react';
import { Container, Form, Col, Button } from 'react-bootstrap';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const Contact = () => {
  const [formData, setFormData] = useState({ interest: 'Keynote Speech' });
  const [submitted, setSubmitted] = useState(false);
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formData }),
    })
      .then(() => setSubmitted(true))
      .catch((error) => alert(error));
  };
  return (
    <section id="contact">
      <Container>
        {submitted ? (
          <>
            <h1 className="w-100 text-center">Thanks for contacting us</h1>
            <h2 className="w-100 text-center mb-5 pr-md-5">We will get back to you shortly.</h2>
            <AniLink cover direction="down" to="/" bg="#3486fb">
              <Button className="cta-btn d-block w-30 mx-auto mt-5">Return to homepage</Button>
            </AniLink>
          </>
        ) : (
          <>
            <h1 className="w-100 text-center">Contact Dr Gio Valiante</h1>
            <h2 className="w-100 text-center mb-5 pr-md-5">Personal info</h2>
            <Form
              className="mt-5"
              name="contact"
              onSubmit={onSubmit}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <Form.Row>
                <Form.Group as={Col} lg={4} md={6} xs={12} className="mb-5 pr-md-5 ">
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="first-name"
                    required
                    onChange={onChange}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={4} md={6} xs={12} className="mb-5 pr-md-5 ">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="last-name"
                    required
                    onChange={onChange}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={4} md={6} xs={12} className="mb-5 pr-md-5 ">
                  <Form.Label>Email</Form.Label>
                  <Form.Control size="lg" type="email" name="email" required onChange={onChange} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} lg={4} md={6} xs={12} className="mb-5 pr-md-5 ">
                  <Form.Label>Phone number (optional)</Form.Label>
                  <Form.Control size="lg" type="text" name="phone-number" onChange={onChange} />
                </Form.Group>

                <Form.Group as={Col} lg={4} md={6} xs={12} className="mb-5 pr-md-5">
                  <Form.Label>Service interested in:</Form.Label>
                  <Form.Control
                    size="lg"
                    as="select"
                    defaultValue="Keynote Speech"
                    name="interest"
                    onChange={onChange}
                  >
                    <option>Keynote Speech</option>
                    <option>Individual Coaching</option>
                    <option>Organizational Coaching</option>
                    <option>Other</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} lg={4} md={6} xs={12} className="mb-5 pr-md-5 ">
                  <Form.Label>Please give us some information about you and the nature of your request.</Form.Label>
                  <Form.Control
                    size="lg"
                    as="textarea"
                    rows="3"
                    name="message"
                    onChange={onChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-center">
                <Button className="cta-btn w-50" type="submit">
                  Submit
                </Button>
              </Form.Row>
            </Form>{' '}
          </>
        )}
      </Container>
    </section>
  );
};

export default Contact;
